export default [
	{
		code: 'es',
		file: 'es.js',
	},
	{
		code: 'nl',
		file: 'nl.js',
	},
	{
		code: 'en',
		file: 'en.js',
	},
	{
		code: 'fr',
		file: 'fr.js',
	},
	{
		code: 'ca',
		file: 'ca.js',
	},
];
