import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___es",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___ca",
    path: "/ca/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___es",
    path: "/contacto",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___ca",
    path: "/ca/contacte",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "faq___es",
    path: "/preguntas-frecuentes",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___nl",
    path: "/nl/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/questions-frequemment-posees",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___ca",
    path: "/ca/preguntes-frequents",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___es",
    path: "/galeria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___ca",
    path: "/ca/galeria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___es",
    path: "/ubicacion",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___nl",
    path: "/nl/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___en",
    path: "/en/location",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___fr",
    path: "/fr/site",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___ca",
    path: "/ca/ubicacio",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___ca",
    path: "/ca",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "packages-item___es",
    path: "/arreglos/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___en",
    path: "/en/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___ca",
    path: "/ca/arranjaments/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___es",
    path: "/arreglos",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___en",
    path: "/en/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___ca",
    path: "/ca/arranjaments",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "reviews___es",
    path: "/resenas",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___ca",
    path: "/ca/resenyes",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "rooms___es",
    path: "/habitaciones",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "rooms___ca",
    path: "/ca/habitacions",
    component: () => import("/vercel/path0/pages/rooms.vue")
  },
  {
    name: "sitemap___es",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___ca",
    path: "/ca/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]