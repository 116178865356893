import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import the specific icon
import { faEnvelope } from '@fortawesome/sharp-regular-svg-icons';

import {
	faBars,
	faCalendarDays,
	faChevronDown,
	faPhone,
	faChevronUp,
	faGlobe,
	faChevronLeft,
	faChevronRight,
	faXmark,
} from '@fortawesome/pro-light-svg-icons';

import {
	faSquareFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faArrows,
	faBedAlt,
	faBellConcierge,
	faCheck,
	faEuroSign,
	faMinus,
	faPlay,
	faPlus,
	faRulerCombined,
	faSearch,
	faStar,
	faStars,
	faQuoteLeft,
	faStarHalf,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrows,
	faBars,
	faBedAlt,
	faBellConcierge,
	faCheck,
	faChevronUp,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCalendarDays,
	faEnvelope,
	faEuroSign,
	faGlobe,
	faQuoteLeft,
	faSquareFacebook,
	faInstagram,
	faLinkedin,
	faMinus,
	faPinterest,
	faPhone,
	faPlay,
	faPlus,
	faRulerCombined,
	faSearch,
	faSoundcloud,
	faSpotify,
	faStars,
	faTiktok,
	faTwitter,
	faUsers,
	faWhatsapp,
	faYoutube,
	faStar,
	faStarHalf,
	faXTwitter,
	faXmark,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
